import React, { useState } from "react";
import PropTypes from "prop-types";
import { API_ENDPOINTS } from "../config/ApiConfig";
import "./InterviewQuestions.css"; // Import CSS for styling

const InterviewQuestions = ({ jd_text, email, sb_unique_id }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRoleClick = async (role) => {
    console.log(`Role clicked: ${role}`);
    setLoading(true);

    try {
      console.log("Sending request to API...");
      const response = await fetch(API_ENDPOINTS.INTERVIEW_QUESTIONS, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question_owner: role,
          jd_text,
          email,
          sb_unique_id,
        }),
      });

      if (response.ok) {
        console.log("API request successful!");
        const data = await response.json();
        console.log("Response data:", data);

        // Correctly mapping the API response to the state
        setQuestions(data.Questions || []);
      } else {
        console.error(
          "Failed to fetch questions. Response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error while fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCommentSave = async (questionId, comment) => {
    console.log(`Saving comment for question ID: ${questionId}`);

    try {
      // Sending the comment to the update_comment API along with required fields
      const response = await fetch(API_ENDPOINTS.UPDATE_COMMENT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: questionId,
          comment,
          unique_id: sb_unique_id,
          email,
          role: "HR Screener", // Adjust as per the role, if needed
        }),
      });

      if (response.ok) {
        console.log("Comment saved successfully!");
        const data = await response.json();
        console.log("Response data:", data);
      } else {
        console.error(
          "Failed to save comment. Response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error while saving comment:", error);
    }
  };

  return (
    <div className="interview-questions">
      <div className="button-container">
        {["HR Screener", "Line Manager", "Senior Manager"].map((role) => (
          <button
            key={role}
            className="role-button"
            onClick={() => handleRoleClick(role)}
          >
            {role}
          </button>
        ))}
      </div>

      {loading && <p className="loading">Loading questions...</p>}

      {questions.length > 0 && (
        <div className="questions-container">
          <h3>Interview Questions</h3>
          {questions.map((question) => (
            <div key={question.id} className="question-item">
              <p>{question.text}</p>
              <textarea
                placeholder="Enter the answer"
                rows={3}
                value={question.comment || ""}
                onChange={(e) => {
                  setQuestions((prev) =>
                    prev.map((q) =>
                      q.id === question.id
                        ? { ...q, comment: e.target.value }
                        : q
                    )
                  );
                }}
              />
              {question.comment && question.comment.trim() !== "" && (
                <button
                  onClick={() =>
                    handleCommentSave(question.id, question.comment)
                  }
                >
                  Save Answer
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

InterviewQuestions.propTypes = {
  jd_text: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sb_unique_id: PropTypes.string.isRequired,
};

export default InterviewQuestions;

import React, { useContext, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../State/AuthContext";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import { API_ENDPOINTS } from "../config/ApiConfig";

let CLIENT_ID =
  "953011688496-85lfc71tkfa6qhbdjavvlhf4nrtg9um3.apps.googleusercontent.com";

if (process.env.NODE_ENV === "production") {
  CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;
}

const Login = () => {
  const { setEmail, setAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous errors

    try {
      const response = await fetch(API_ENDPOINTS.AUTHENICATION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }), // Send email and password
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Login failed";
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log("Login successful:", data);

      // Save the token in both localStorage and state
      localStorage.setItem("access_token", data.access_token);
      setAccessToken(data.access_token);

      // Set the email in context
      setEmail(username);

      // Redirect after successful login
      navigate("/");
    } catch (err) {
      console.error("Login error:", err);
      setError(err.message); // Set the error message for display
    }
  };

  const handleLoginSuccess = (response) => {
    try {
      const decodedResponse = jwtDecode(response.credential);
      const userEmail = decodedResponse.email;

      // Set the email and access token in context
      setEmail(userEmail);
      setAccessToken(response.credential);

      // Save token to localStorage
      localStorage.setItem("access_token", response.credential);

      navigate("/");
    } catch (error) {
      console.error("Error decoding JWT:", error);
      alert("Failed to process login. Please try again.");
    }
  };

  const handleLoginFailure = (response) => {
    console.error("Login Failure:", response);
    alert("Google login failed. Please try again.");
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        {error && <p className="error-message">{error}</p>}
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <div>OR</div>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          useOneTap={true}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default Login;

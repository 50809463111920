import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Notification from "./Components/Notification";
import { AppProviders } from "./Components/AppProviders"; // Extracted Providers
import { HRRoutes } from "./Routes/HRRoutes"; // Modularized HR Routes
import { EmployeeRoutes } from "./Routes/EmployeeRoutes"; // Modularized Employee Routes
import HomePage from "./Pages/HomePage/HomePage";
import "./App.css";
import { AuthContext } from "./State/AuthContext";

function App() {
  return (
    <AppProviders>
      <Router>
        <Notification />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {HRRoutes()} {/* HR-related routes */}
          {EmployeeRoutes()} {/* Employee-related routes */}
        </Routes>
      </Router>
    </AppProviders>
  );
}

export default App;

import React from "react";

// Function to create a bullet list from an array
const createBulletList = (items) => {
  return items.map((item) => <li key={item}>{item}</li>);
};

const JobDescriptionComparisonTable = ({ compareData }) => {
  // Features array (the rows we will compare)
  const features = [
    "jobTitle",
    "primaryFocus",
    "technologyExpertiseRequired",
    "keyResponsibilities",
    "roleInProjects",
    "stakeholderInteraction",
    "performanceMetric",
    "emphasis",
  ];

  return (
    <table
      style={{
        width: "100%",
        border: "1px solid #ccc",
        borderCollapse: "collapse",
        marginTop: "20px",
      }}
    >
      <thead>
        <tr style={{ backgroundColor: "#f4f4f4" }}>
          <th style={{ padding: "8px", textAlign: "left", fontWeight: "bold" }}>
            Feature
          </th>
          <th style={{ padding: "8px", textAlign: "left", fontWeight: "bold" }}>
            Original JD
          </th>
          <th style={{ padding: "8px", textAlign: "left", fontWeight: "bold" }}>
            SkillsBridge.AI JD
          </th>
        </tr>
      </thead>
      <tbody>
        {features.map((feature) => {
          const originalJD =
            compareData.jobDescriptionComparison["Original JD"][feature];
          const skillsbridgeAIJD =
            compareData.jobDescriptionComparison["SkillsBridge.AI JD"][feature];

          return (
            <tr key={feature}>
              <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                {feature.charAt(0).toUpperCase() +
                  feature.slice(1).replace(/([A-Z])/g, " $1")}
              </td>
              <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                {Array.isArray(originalJD) ? (
                  <ul>{createBulletList(originalJD)}</ul>
                ) : (
                  originalJD
                )}
              </td>
              <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                {Array.isArray(skillsbridgeAIJD) ? (
                  <ul>{createBulletList(skillsbridgeAIJD)}</ul>
                ) : (
                  skillsbridgeAIJD
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default JobDescriptionComparisonTable;
